export default {
  action: {
    backToHome: 'Back to home page',
    cancel: 'Cancel',
    cancelSubscription: 'Cancel subscription',
    confirm: 'Confirm',
    continue: 'Continue',
    clear: 'Clear',
    close: 'Close',
    leaveFeedback: 'Leave feedback',
    ok: 'OK',
    payAmount: 'Pay {amount}',
    restore: 'Restore',
    restoreSubscription: 'Restore subscription',
    selectPlan: 'Select plan',
    selectYourPlan: 'Select your plan',
    see: 'See',
    signIn: 'Sign in',
    signOut: 'Sign out',
    signInMethod: {
      facebook: 'Continue with Facebook',
      google: 'Continue with Google',
    },
    submit: 'Submit',
    unsubscribe: 'Unsubscribe',
    upgrade: 'Upgrade',
    upgradePlan: 'Upgrade plan',
  },
  category: {
    a: 'Core / human capital factors',
    b: 'Spouse or common-law partner factors',
    c: 'Skill transferability factors',
    d: 'Additional points',
  },
  confirm: {
    cancelSubscription:
      'Are you sure you want to cancel your subscription? You will retain access to all the features provided by your plan until the current billing period ends.',
    signOut: 'Are you sure you want to sign out of your account?',
  },
  label: {
    age: 'Age',
    blog: 'Blog',
    canadianWorkExp: 'Canadian work experience',
    foreignWorkExp: 'Foreign work experience (non-Canadian)',
    canadianWorkExpYears: 'Years of Canadian work experience',
    foreignWorkExpYears: 'Years of foreign (non-Canadian) work experience',
    canadianEducation: 'Post-secondary education in Canada',
    cannotDowngrade: 'Downgrade unavailable',
    condition: 'Condition',
    coq: 'Certificate of qualification',
    couple: 'Couple',
    coupleOneKid: 'Couple & one child',
    education: 'Education',
    educationLevel: 'Level of education',
    eligibility: 'Eligibility',
    email: 'E-mail',
    expenses: 'Expenses',
    firstLanguageTest: 'First official language test',
    secondLanguageTest: 'Second official language test',
    features: 'Features',
    free: 'Free',
    frenchBonus: 'French language bonus',
    languageTest: 'Language test',
    guide: 'Guide',
    help: 'Help',
    ita: 'ITA',
    jobOffer: 'Job offer',
    language: 'Language',
    langProficiency: 'Language proficiency',
    latestRoundPoints: 'Latest round points',
    link: 'Link',
    moreDetails: 'more details',
    name: 'Name',
    never: 'Never',
    none: 'None',
    noneOr: 'None or {value}',
    notSelected: 'Not selected',
    notRequired: 'Not required',
    plans: 'Plans',
    pnp: 'Provincial or territorial nomination',
    points: 'Points',
    pool: 'Pool',
    pr: 'PR',
    price: 'Price',
    profile: 'Profile',
    programs: 'Programs',
    qualificationCertificate: 'Certificate of qualification',
    result: 'Result',
    rounds: 'Rounds',
    single: 'Single',
    spouseAge: "Your spouse's age",
    spouseEducation: "Spouse's education",
    spouseLanguage: "Spouse's language level",
    spouseWorkExp: "Spouse's Canadian work experience",
    statusCenter: 'My Plan',
    step: 'Step',
    subscriptions: 'Subscriptions',
    time: 'Time',
    type: 'Type',
    totalPoints: 'Total points',
    upgradePlan: 'Premium',
    workExp: 'Canadian work experience',
    workExperienceAmount: 'Amount of work experience',
    workExperienceType: 'Type of work experience',
    yourAge: 'Your age',
    yourEducation: 'Your education level',
    yourName: 'Your name',
    yourMessage: 'Your message',
  },
  message: {
    signInRequired: 'Sign in required',
    thanksForFeedback: 'Thank you for your feedback!',
  },
  calc: {
    pointsInCategory: 'Points in this category',
    totalForPart: 'Total for part {part}',
    explanation: {
      withSpouse: 'With a spouse or common-law partner',
      withoutSpouse: 'Without a spouse or common-law partner',
      maxPoints: 'Maximum {maxPoints} points',
      clbPerAbility:
        'Canadian Language Benchmark (CLB) level per ability (reading, writing, speaking and listening)',
      clb: {
        min: 'Less than CLB {score}',
        max: 'CLB {score} or more',
        score: 'CLB {score}',
        range: 'CLB {start} or {end}',
      },
      workExperience: {
        years: 'None or less than a year | 1 year | {years} years',
        maxYears: '{years} years or more',
        info: 'The work experience must be paid and full-time (or an equal amount in part-time)',
      },
      frenchBonus: 'Bonus for French language proficiency',
    },
  },
  eligibility: {
    criteria: {
      workExp: 'Work experience',
      workExpType: 'Type of work experience',
      workExpAmount: 'Amount of work experience',
      language: 'Language skills',
      jobOffer: 'Job offer',
      jobOfferOrCert: 'Job offer or certificate of qualification',
      education: 'Education',
      proofOfFunds: 'Proof of funds',
      fswSelectionFactors: 'Selection factors',
    },
  },
  questionnaire: {
    pleaseSignIn: 'Please sign in to receive your results.',
  },
  questions: {
    certificateOfQualification:
      'Do you have a certificate of qualification issued by a Canadian provincial, territorial or federal authority?',
  },
  profile: {
    language: {
      tests: {
        NONE: 'Not applicable',
        CELPIP: 'CELPIP General (English)',
        IELTS: 'IELTS General (English)',
        TEF: 'TEF Canada (French)',
        TCF: 'TCF Canada (French)',
      },
      skills: {
        l: 'Listening',
        r: 'Reading',
        s: 'Speaking',
        w: 'Writing',
      },
    },
    canadianEducation: {
      oneOrTwoYears: 'Post-secondary credential of one or two years',
      threeOrMoreYears:
        "Credential of three years or longer (Bachelor's, Master's, Professional (NOC A), or Doctoral (PhD) degree)",
    },
    educationLevels: {
      lessThanSecondary: 'Less than secondary school (high school)',
      secondary: 'Secondary diploma (high school graduation)',
      oneYearDegree:
        'One-year degree, diploma or certificate from a university, college, trade or technical school, or other institute',
      twoYearDegree:
        'Two-year program at a university, college, trade or technical school, or other institute',
      bachelorsDegree:
        "Bachelor's degree OR a three or more year program at a university, college, trade or technical school, or other institute",
      twoDegrees:
        'Two or more certificates, diplomas, or degrees. One must be for a program of three or more years',
      mastersDegree:
        "Master's degree, OR professional degree needed to practice in a licensed profession (For “professional degree,” the degree program must have been in: medicine, veterinary medicine, dentistry, optometry, law, chiropractic medicine, or pharmacy.)",
      phd: 'Doctoral level university degree (Ph.D.)',
    },
    shortEducationLevels: {
      lessThanSecondary: 'Less than secondary school (high school)',
      secondary: 'Secondary diploma (high school graduation)',
      oneYearDegree: 'One-year degree, diploma or certificate',
      twoYearDegree:
        'Two-year program at a university, college, trade or technical school, or other institute',
      bachelorsDegree:
        "Bachelor's degree OR a three or more year program at a university, college, trade or technical school, or other institute",
      twoDegrees: 'Two or more certificates, diplomas, or degrees',
      mastersDegree:
        "Master's degree, OR professional degree needed to practice in a licensed profession",
      phd: 'Doctoral level university degree (Ph.D.)',
    },
    tinyEducationLevels: {
      lessThanSecondary: 'Less than secondary school (high school)',
      secondary: 'Secondary school (high school)',
      oneYearDegree: 'One-year degree, diploma or certificate',
      twoYearDegree: 'Two-year degree, diploma or certificate',
      bachelorsDegree: "Bachelor's degree",
      twoDegrees: 'Two or more degrees, diplomas or certificates',
      mastersDegree: "Master's degree OR professional degree",
      phd: 'Doctoral level university degree (PH.D.)',
    },
    currentPlan: 'Current plan',
    lastPayment: 'Last payment',
    nextPaymentDue: 'Next payment due',
    subscriptionCanceled: 'Subscription canceled',
    validUntil: 'Valid until',
  },
  expenses: {
    results: {
      paper: 'Paper',
      email: 'E-mail',
      receipt: 'Receipt',
      nothing: 'Nothing',
    },
  },
  feature: {
    saveProfile: 'Save profile',
    spouseProfile: 'Spouse profile',
    poolGuide: 'Pool submission guide',
    pointsImprovementGuide: 'Points improvement guide',
    advancedGuide: 'Advanced how-tos',
    documentList: 'Application document list',
    expirationAndChanges: 'Expiration and changes',
    prApprovalGuide: 'PR approval guide',
    prLandingGuide: 'PR landing guide',
    firstWeekInCanada: 'First week in Canada',
  },
  plural: {
    day: '1 day | {count} days',
    month: '1 month | {count} months',
    week: '1 week | {count} weeks',
    year: '1 year | {count} years',
  },
  interval: {
    day: 'per day | every {count} days',
    month: 'per month | every {count} months',
    year: 'per year | every {count} years',
  },
  intervalAlt: {
    day: 'daily',
    month: 'monthly',
    twoMonths: 'two months',
    fourMonths: 'four months',
    sixMonths: 'six months',
    year: 'yearly',
  },
  intervalShort: {
    month: 'mo',
    twoMonths: '2 mo',
    fourMonths: '4 mo',
    sixMonths: '6 mo',
    year: 'yr',
  },
}
